<script>
export default {
  components: {},
  name: 'TermsForm',
  data () {
    return {
      links: [
        { name: this.$t('terms.of.service.form:privacy.br'), link: 'https://assets.plooral.io/legal/privacy_notice_pt_br' },
        { name: this.$t('terms.of.service.form:privacy.us'), link: 'https://assets.plooral.io/legal/privacy_notice_en_us' },
        { name: this.$t('terms.of.service.form:terms.of.use.br'), link: 'https://assets.plooral.io/assets/plooral-candidate-terms-and-conditions_pt-BR.pdf' },
        { name: this.$t('terms.of.service.form:terms.of.use.us'), link: 'https://assets.plooral.io/assets/plooral-candidate-terms-and-conditions_en-US.pdf' }
      ]
    }
  },
  computed: {
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces.filter(el => el.type === 'business').map(filteredWorkspace => {
        return {
          name: filteredWorkspace.name,
          fieldName: this.$t('terms.of.service.form:terms.of.use'),
          link: filteredWorkspace.region === 'BRA' ? 'https://assets.plooral.io/legal/plooral_hire_tos_pt_br' : 'https://assets.plooral.io/legal/plooral_hire_tos_en_us'
        }
      })
    }
  }
}
</script>
<template>
  <div class='terms-form--container'>
    <div class='region-wrapper'>
      <h4>{{$t('workspace.type:individual')}}</h4>
      <a :href="item.link" target="_blank" v-for="(item, index) in links " :key=index>
        <div class="term-item">{{item.name}}
        <v-icon >mdi-launch</v-icon>
      </div>
      </a>
    </div>
    <a :href="item.link" target="_blank" v-for="(item, index) in userWorkspaces " :key=index>
    <div class='region-wrapper'>
      <h4>{{item.name}}</h4>
      <div class="term-item">{{item.fieldName}}
        <v-icon >mdi-launch</v-icon>
      </div>
    </div>
    </a>
  </div>
</template>
<style lang='scss'>
.terms-form--container {
  a:link {
    text-decoration: inherit;
    color: inherit;
  }
  a:visited {
    text-decoration: inherit;
    color: inherit;
  }
  .region-wrapper {
    margin-bottom: 25px;
    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-size: 16px;
    }
    .term-item {
      .v-icon {
        color: $primary-medium;
      }
      height: auto;
      display:flex;
      justify-content: space-between;
      font-family: 'Lato';
      font-style: normal;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.06);
      padding: 15px;
      margin-top: 15px;
    }
  }
}

</style>
